
  
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
    
  }
  
  .online .card {
    background-color: #ffffff1e;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin: 10px;
    padding: 20px;
    width: 250px;
    text-align: center;
    z-index: 2;
  }
  
  .icon {
    font-size: 36px;
    color: var(--global-color); /* Customize color as needed */
    margin-bottom: 10px;
  }
  
  .online .card h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  
  .online .card ul {
    list-style-type: disc;
    padding: 0;
    margin: 0;
    color: #787878;
    padding: 0 0.5rem;
  }
  
  .online .card li {
    font-size: 14px;
    margin-bottom: 8px;
    text-align: start;
    
  }
  