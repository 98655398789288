.admin-panel .card-body span{
    color: white;
    font-size: 30px;
    font-weight: 600;
}
.print-invoice{
        position:absolute;
        top: 0;
        left: 0;
        width: 100%;
        flex-direction: column;
        background-color: rgba(0, 0, 0, 0.5);
        margin-top: 3rem;
      
        z-index: 9;
     
       
}
.main-admin-dash{
    margin-top: 3rem;
}
.cancel-invoice{
    color: white;
    font-size: 20px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    text-align: end;
    margin: 1rem 1rem;
   

}
.activit2 span{
    color: white;
    font-size: 30px;
    font-weight: 600;
}
.activit1 span{
    color: white;
    font-size: 30px;
    font-weight: 600;
}
.activit3 span{
    color: white;
    font-size: 30px;
    font-weight: 600;
}
.activit4 span{
    color: white;
    font-size: 30px;
    font-weight: 600;
}
.dataImg{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.301);
    background-position: center center;
    background-size:cover;
    overflow: scroll;
}
.dataImg svg{
    position:fixed;
    top:0;
    right:0;
    cursor: pointer;
    margin: 1rem 2rem;
}
.dataImg img{
    width: 90%;
    object-fit: contain;
}