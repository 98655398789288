.signup{
    position: relative;
  
  }

  .signupform{ 
    background-color: #056905c2;
      background-size: cover;
      height: 100vh;
      
      
  }
  /* .signupform::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.397);
    } */
  .form {
      position: absolute;
      top:50%;
      left:50%;
      transform: translate(-50%,-50%);
      background-color: #fafafa;
      display: block;
      padding: 1rem;
      max-width: 350px;
      border-radius: 0.5rem;
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
      
    }
    
    .form-title {
      font-size: 1.25rem;
      line-height: 1.75rem;
      font-weight: 600;
      text-align: center;
      color: var(--global-color);
      margin-bottom: 1rem;
  
      
    }
    
    .input-container {
      position: relative;
    }
    
    .input-container input, .form button {
      outline: none;
      border:none;
      margin: 8px 0;
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
    }
    
    .input-container input {
      background-color: #fff;
      padding: 1rem;
      padding-right: 3rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
      width: 300px;
      border-radius: 0.5rem;
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    }
    
    .input-container span {
      display: grid;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      padding-left: 1rem;
      padding-right: 1rem;
      place-content: center;
    }
    
    .input-container span svg {
      color: #9CA3AF;
      width: 1rem;
      height: 1rem;
    }
    
    .submit {
      display: block;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      background-color: var(--global-color);
      border: none;
      color: #ffffff;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 500;
      width: 100%;
      border-radius: 0.5rem;
      cursor: pointer;
      
    }
    
    .signup-link {
      color: #6B7280;
      font-size: 0.875rem;
      line-height: 1.25rem;
      text-align: center;
    }
    
    .signup-link a {
      text-decoration: underline;
      color: #5F7AE5;
      font-weight: 700;
      margin-left: 2px;
  
    }
    .error{
      color:rgb(226, 41, 9);
    
   
      padding: 2px 3px;
    }